<template>
  <div class="addaddress">
    <Header></Header>
    <div class="breadcrumb-wrap bg-f br-1">
      <div class="overlay bg-black op-9"></div>
      <img :src="shape1" alt="Image" class="br-shape-1" />
      <img :src="shape2" alt="Image" class="br-shape-2" />
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="breadcrumb-title">
              <h2>Add Address</h2>
              <ul class="breadcrumb-menu list-style">
                <li>
                  <!--<a href="index-2.html">Home </a>--><router-link
                    to="/"
                    style="color: white"
                    >Home</router-link
                  >
                </li>
                <li>Add Address</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blog-details-wrap ptb-100">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-4 col-lg-12 order-xl-1 order-lg-2 order-md-2 order-2"
          >
            <div class="sidebar">
              <Leftsidebar></Leftsidebar>
            </div>
          </div>
          <div
            class="col-xl-8 col-lg-12 order-xl-2 order-lg-1 order-md-1 order-1"
          >
            <div class="bg-white mb-4">
              <h4 class="border-bottom pb-4">
                <i
                  class="fa fa-plus-square text-default mr-3"
                  aria-hidden="true"
                ></i>
                Add Manage Address
              </h4>
              <div class="row mb-5">
                <div class="col-md-6">
                  <div ref="mapDiv" style="width: 100%; height: 400px" />
                </div>
                <div class="col-md-6">
                  <form @submit.prevent="address">
                    <vue-google-autocomplete
                      ref="address"
                      id="map"
                      classname="form-control"
                      placeholder="Search Location"
                      v-on:placechanged="getAddressData"
                      country="ca"
                      name="address"
                    >
                    </vue-google-autocomplete>
                    <div class="display-list mt-1">
                      <p>
                        <a class="cursor-pointer" @click="currentLoc"
                          ><i
                            class="fa fa-location-arrow"
                            aria-hidden="true"
                          ></i>
                          Use My Current Location</a
                        >
                      </p>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-md-12">
                        <h2 style="font-size: 16px" class="font-weight-bold">
                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                          New Address
                        </h2>
                      </div>
                      <div class="input-group mt-2">
                        <input
                          type="text"
                          id="location"
                          class="form-control"
                          v-model="form.address"
                          placeholder="Location"
                        />
                      </div>
                      <div class="input-group mt-2">
                        <input
                          type="text"
                          id="house"
                          class="form-control"
                          v-model="form.house"
                          placeholder="Full Address"
                        />
                      </div>
                      <div class="input-group mt-2">
                        <select
                          class="form-control form-select text-dark"
                          aria-label="Default select example"
                          v-model="form.tag"
                        >
                          <option selected>Save Favorites Address</option>
                          <option value="Home">Home</option>
                          <option value="Work">Work</option>
                        </select>
                      </div>
                      <div class="col-md-12 mt-2">
                        <button class="btn style1 w-100" type="submit">
                          <span>Save Address</span>
                        </button>
                      </div>
                      <span class="text-danger" v-if="error">{{ error }}</span>
                    </div>
                    <!-- /input-group -->
                  </form>
                </div>
                <!-- /.col-lg-6 -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/views/layouts/Header.vue";
import Leftsidebar from "@/views/sidebar/Leftsidebar.vue";
import Footer from "@/views/layouts/Footer.vue";
import { Loader } from "@googlemaps/js-api-loader";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { saveAddress } from "@/store/api";
import axios from "axios";
export default {
  props: {},
  name: "addaddress",
  data() {
    return {
      shape1: require("@/assets/img/shape-1.png"),
      shape2: require("@/assets/img/shape-2.png"),
      form: {
        house: "",
        address: "",
        search: "",
        tag: "",
      },
      error: "",
      lat: "",
      lng: "",
      map: null,
    };
  },
  components: {
    Header,
    Leftsidebar,
    Footer,
    VueGoogleAutocomplete,
  },
  mounted() {
    this.showMap();
  },
  methods: {
    currentLoc() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition);
      } else {
        this.error = "Geolocation is not supported.";
      }
    },
    showPosition(position) {
      this.form.latitude = position.coords.latitude;
      this.form.longitude = position.coords.longitude;

      const marker = new window.google.maps.Marker({
        position: { lat: this.form.latitude, lng: this.form.longitude },
      });
      marker.setMap(this.map);

      this.getCurrentLoc(this.form.latitude, this.form.longitude);
    },
    async showMap() {
      const directionsRenderer = new window.google.maps.DirectionsRenderer();
      const GOOGLE_MAPS_API_KEY = "AIzaSyBLVIcbGHiO0lFwfgZgKBx9UlSz_yrl_IU";
      const loader = new Loader({ apiKey: GOOGLE_MAPS_API_KEY });
      const mapDiv = this.$refs.mapDiv;
      await loader.load();
      this.map = new window.google.maps.Map(mapDiv, {
        center: { lat: 51.033332421752895, lng: -114.07961939718315 },
        // center: currPos.value,
        zoom: 10,
      });
      directionsRenderer.setMap(this.map);
    },
    getCurrentLoc(lat, lng) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBLVIcbGHiO0lFwfgZgKBx9UlSz_yrl_IU`
        )
        .then((res) => {
          let loc = "";
          res.data.results.filter(function (item) {
            if (item.types[0] === "route") {
              loc = item.formatted_address;
            }
          });
          // document.getElementById('currentLocation').innerHTML = loc;
          this.form.address = loc;
        });
    },
    getAddressData(addressData, placeResultData) {
      this.form.address = placeResultData.formatted_address;
      this.form.longitude = addressData.longitude;
      this.form.latitude = addressData.latitude;
    },
    address() {
      this.error = "";
      if (!this.form.house || !this.form.address || !this.form.tag) {
        this.error = "All fields are required.";
        this.$toast.error("All fields are required.");
      }
      if (this.error === "") {
        saveAddress(this.form)
          .then((res) => {
            console.log(res.data);
            this.$toast.success("New address successfully");
            if (localStorage.getItem("page") === "checkout") {
              localStorage.removeItem("page");
              this.$router.push("/checkout");
            } else {
              this.$router.push("/manageaddress");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style>
ul.text-list li.text-link {
  list-style: none;
  font-size: 15px;
  line-height: 3;
}
ul.text-list {
  padding-left: 7px;
}
li.text-link i {
  color: #ddae71;
}
a.text-left {
  margin-left: 15px;
}
a.text-left.active {
  color: #ddae71;
  font-weight: 600 !important;
}
.heading-text {
  font-size: 18px;
  font-weight: 700;
  margin-top: 8px;
}
</style>
